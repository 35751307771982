import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Immobilienfinanzierung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader
                h1Title="Was muss ich bei der Immobilienfinanzierung beachten? – Ein Überblick"
                showCalc={false}
            />
            <Article>
                <h2>Das kleine 1x1 der Immobilienfinanzierung</h2>
                <p>
                    Du hast dich für den Kauf einer Immobilie entschieden oder spielst zumindest mit dem Gedanken, ein
                    Haus oder eine Wohnung zu erwerben? In beiden Fällen gilt es, zunächst herauszufinden, welche
                    Finanzierung für dich leistbar ist. Wir unterstützen dich dabei mit dem folgenden Beitrag.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Der erste Schritt: Die Haushaltsrechnung</h2>
                <p>
                    Vor der Umsetzung kommt natürlich immer die Planung. Wir empfehlen dir deshalb, gleich zu Beginn
                    eine Haushaltsrechnung durchzuführen, um denjenigen Teil deines Einkommens zu bestimmen, der dir für
                    eine monatliche Kreditrate zur freien Verfügung steht. Der erste Schritt den du hierfür machen ist:
                    Addiere deine monatlichen Einkünfte. Dazu gehören
                </p>
                <ul>
                    <li>dein Nettoeinkommen (Einkommen exklusive Steuern und Sozialabgaben)</li>
                    <li>Einkünfte durch Beihilfen (z.B. Kinder-, Familien- oder Studienbeihilfe)</li>
                    <li>etwaige Mieteinnahmen</li>
                    <li>allfällige Renten</li>
                </ul>
                <p>
                    Bedenke hierbei auch, dass etwaige Beihilfen während der Kreditlaufzeit eingestellt werden könnten
                    und sich dein Einkommen dadurch, ganz ohne ein Verschulden deinerseits, ein wenig verringern könnte.
                </p>
                <p>
                    Hast du alle deine Einkünfte addiert und die Rechnung gründlich überprüft, bist du bereit für den
                    nächsten Schritt, in dem es um deine monatlichen Ausgaben geht. Dafür addierst du ganz einfach deine{" "}
                    <strong>Fix- und Lebenshaltungskosten</strong>. Zu diesen gehören
                </p>
                <ul>
                    <li>Miete sowie Betriebs- und Energiekosten</li>
                    <li>Kosten für Telefon, Internet und Rundfunk</li>
                    <li>Mobilitätskosten (Kosten für eigene Fahrzeuge und/oder öffentliche Verkehrsmittel)</li>
                    <li>Versicherungen</li>
                    <li>Abonnements von Zeitschriften, Streamingdiensten etc.</li>
                    <li>Unterhaltszahlungen, Verpflegung und Kleidung</li>
                    <li>und auch alle Ausgaben für deine Hobbies</li>
                </ul>
                <p>
                    Schließlich ziehst du deine Ausgaben ganz einfach von deinen monatlichen Einnahmen ab. Die Differenz
                    zwischen monatlichen Einkünften und Ausgaben ergibt dann dein frei verfügbares Einkommen. Ganz
                    einfach, oder? Richtig.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Zum Beratungsgespräch
                </a>
                <hr />

                <h2>Vom Haushaltseinkommen ganz einfach zur Kreditrate</h2>
                <p>
                    Da du jetzt einen guten Überblick über dein Haushaltseinkommen hast, kannst du als nächstes die für
                    dich leistbare Kreditrate berechnen. Wichtig: Achte unbedingt darauf, genügend Reserven einzuplanen
                    – das ist nicht nur deswegen ratsam, weil es zu unvorhergesehenen Ausgaben wie Reparaturen oder
                    Neuanschaffungen kommen kann. Da manche{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredite
                    </Link>{" "}
                    mit variabler Verzinsung arbeiten, solltest du zudem in der Lage sein, die mit steigenden{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    ebenfalls erhöhte Rate zu begleichen. Eine erste Orientierungshilfe hinsichtlich des Kreditvolumens,
                    das du mit deinen finanziellen Möglichkeiten voraussichtlich erwarten kannst, bietet übrigens unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/">Online-Kreditrechner</Link>.
                </p>
                <hr />

                <h2>Wieviel Eigenkapital benötige ich beim Immobilienkauf?</h2>
                <p>
                    Unter Eigenkapital ist die Summe an Erspartem zu verstehen, die du selbst zur Finanzierung der
                    Immobilie aufwendest. Je größer der Anteil an Eigenkapital, umso unwahrscheinlicher ist ein
                    Zahlungsausfall deinerseits, was ein vergleichsweise geringes Risiko für die jeweilige Bank bedeutet
                    und ihre Bereitschaft, dir einen Kredit zu gewähren, entsprechend erhöht. Folglich erhältst du bei
                    einem größeren Eigenkapitalanteil meist auch attraktive Konditionen sowie niedrigere Zinsen.
                    Natürlich fällt auch das benötigte Kreditvolumen in diesem Fall geringer aus, wodurch die
                    Tilgungssätze gleichfalls sinken.
                </p>
                <p>Zum Eigenkapital gehören:</p>
                <ul>
                    <li>Bargeld</li>
                    <li>Sparbücher und -briefe</li>
                    <li>Tages- und Festgeldkonten</li>
                    <li>Aktien und Zinspapiere</li>
                    <li>Gold und Silber</li>
                    <li>Fonds bzw. Anteile an diesen</li>
                    <li>Rückkaufswerte von Lebensversicherungen</li>
                    <li>Bausparguthaben</li>
                    <li>bebaubare Grundstücke sowie Baumaterialien</li>
                    <li>Etwaige Darlehen von Verwandten oder Arbeitgeber*innen</li>
                </ul>
                <p>
                    Empfehlenswert ist es, über zumindest 20% (idealerweise 30%) an Eigenkapital zu verfügen. Tust du
                    das nicht, so solltest du ein entsprechend hohes konstantes Einkommen nachweisen können.
                </p>
                <hr />

                <h2>Was sind die Gesamtkosten beim Immobilienkauf?</h2>
                <p>
                    Viele Menschen, die sich das erste Mal mit dem Thema Immobilienkauf beschäftigen, vergessen, dass
                    Sie neben der Kreditsumme auch noch mit weiteren Kosten rechnen müssen: Dazu gehören 3%
                    Maklerprovision, 3,5% Grunderwerbsteuer, eine Gebühr für die Grundbucheintragung von 1,2%,
                    Notar*innen- bzw. Anwält*innenkosten von 1-2% sowie Vermittlungs- und Bearbeitungskosten. Wie du bei
                    allen Punkten, vor allem aber bei den Vermittlungskosten sparen kannst? Richtig, mit miracl.
                </p>
            </Article>
            <MortgageCalculator noTopMargin />

            <BreadcrumbList page={"immobilienfinanzierung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"immobilienfinanzierung"}
                heading={"Was muss ich bei der Immobilienfinanzierung beachten? – Ein Überblick"}
            />
        </Layout>
    );
};

export default Immobilienfinanzierung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "mortgageCalc", "page.artikel.immobilienfinanzierung"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
